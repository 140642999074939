<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">财务管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">学员缴费管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="学员姓名" class="searchboxItem ci-full">
              <span class="itemLabel">学员姓名:</span>
              <el-input v-model="retrievalData.userName" placeholder="请输入学员姓名" type="text" clearable size="small" />
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input v-model="retrievalData.mobile" placeholder="请输入手机号" type="text" clearable size="small" />
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input v-model="retrievalData.idcard" placeholder="请输入身份证号" type="text" clearable size="small" />
            </div>
            <div title="审核结果" class="searchboxItem ci-full">
              <span class="itemLabel">审核结果:</span>
              <el-select v-model="retrievalData.auditState" placeholder="请选择审核结果" size="small" clearable>
                <el-option v-for="item in auditStateList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="培训工种" class="searchboxItem ci-full">
              <span class="itemLabel">培训工种:</span>
              <el-select v-model="retrievalData.occupationId" placeholder="请选择培训工种" clearable size="small">
                <el-option v-for="item in occupationList" :key="item.occupationId" :label="item.occupationName"
                  :value="item.occupationId">
                  <span style="float: left">{{ item.occupationName }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.trainLevel }}</span>
                </el-option>
              </el-select>
            </div>
            <div title="招生老师" class="searchboxItem ci-full">
              <span class="itemLabel">招生老师:</span>
              <el-select v-model="retrievalData.teacherId" placeholder="请选择招生老师" clearable size="small">
                <el-option v-for="item in teacherIdList" :key="item.channelId" :label="item.contactUser"
                  :value="item.channelId">
                </el-option>
              </el-select>
            </div>
            <div title="缴费方式" class="searchboxItem ci-full">
              <span class="itemLabel">缴费方式:</span>
              <el-select v-model="retrievalData.paymentMethod" placeholder="请选择缴费方式" size="small" clearable>
                <el-option v-for="item in paymentMethodList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="缴费状态" class="searchboxItem ci-full">
              <span class="itemLabel">缴费状态:</span>
              <el-select v-model="retrievalData.paymentState" placeholder="请选择缴费状态" size="small" clearable>
                <el-option v-for="item in paymentStateList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="报名日期" class="searchboxItem">
              <span class="itemLabel">报名日期:</span>
              <el-date-picker v-model="retrievalData.registrationTime" size="small" type="daterange" style="width: 5rem"
                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value="yyyy-MM-dd"
                value-format="yyyy-MM-dd" />
            </div>
            <div title="工作单位" class="searchboxItem ci-full">
              <span class="itemLabel">工作单位:</span>
              <el-input v-model="retrievalData.workUnit" placeholder="请输入工作单位" type="text" clearable size="small" />
            </div>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="setAddAndEdit()">缴费</el-button>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="setUnpaidFees()">未缴费</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe @selection-change="handleSelectionChange"
              :row-key="(row) => row.projectApplyId">
              <el-table-column type="selection" :reserve-selection="true" width="55" />
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column label="姓名" align="left" show-overflow-tooltip prop="userName" width="100" />
              <el-table-column label="联系方式" align="left" show-overflow-tooltip prop="mobile" width="120" />
              <el-table-column label="培训工种" align="left" show-overflow-tooltip prop="occupationName" width="150" />
              <el-table-column label="培训等级" align="left" show-overflow-tooltip prop="trainLevel" width="100">
                <template slot-scope="scope">
                  {{ $setDictionary("ET_TRAIN_LEVEL", scope.row.trainLevel) }}
                </template>
              </el-table-column>
              <el-table-column label="报名方式" align="left" show-overflow-tooltip prop="joinType" width="120">
                <template slot-scope="scope">
                  {{ $setDictionary("CT_JOIN_TYPE", scope.row.joinType) }}
                </template>
              </el-table-column>
              <el-table-column label="工作单位" align="left" show-overflow-tooltip prop="workUnit" width="120">
              </el-table-column>
              <el-table-column label="缴费状态" align="left" show-overflow-tooltip prop="paymentState" width="120">
                <template slot-scope="scope">
                  {{ $setDictionary("CT_USER_PAYMENT_STATE", scope.row.paymentState) }}
                </template>
              </el-table-column>
              <el-table-column label="缴费方式" align="left" show-overflow-tooltip prop="paymentMethod" width="120">
                <template slot-scope="scope">
                  {{ $setDictionary("CT_USER_APPLY_PAYMENT_METHOD_STATE", scope.row.paymentMethod) }}
                </template>
              </el-table-column>
              <el-table-column label="缴费日期" align="left" show-overflow-tooltip prop="paymentDate" min-width="200">
                <template slot-scope="scope">
                  {{ scope.row.paymentDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column label="实收金额" align="left" show-overflow-tooltip prop="paymentMoney" width="150" />
              <el-table-column label="开票状态" align="left" show-overflow-tooltip prop="invoiceState" min-width="200">
                <template slot-scope="scope">
                  {{ $setDictionary("CT_USER_APPLY_PAYMENT_INVOICE_STATE", scope.row.invoiceState) }}
                </template>
              </el-table-column>
              <el-table-column label="招生老师" align="left" show-overflow-tooltip prop="teacherName" width="120">
                <template slot-scope="scope">
                  {{ scope.row.teacherName ? scope.row.teacherName : '自招' }}
                </template>
              </el-table-column>
              <el-table-column label="报名时间" align="left" show-overflow-tooltip prop="createTime" min-width="200">
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="120px" fixed="right">
                <div slot-scope="scope">
                  <el-button type="text" size="mini" style="padding: 0 5px"
                    @click="setAddAndEdit(scope.row)">缴费</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px"
                    @click="setUnpaidFees(scope.row)">未缴费</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog title="缴费设置" :visible.sync="addAndEditData.dialogVisible" width="500px" :destroy-on-close="true">
      <el-form ref="addAndEditData" class="addAndEditData" :model="addAndEditData" :rules="addAndEditDataRules"
        label-width="100px" size="small" :before-close="cancelData">
        <el-form-item label="缴费时间" prop="paymentDate">
          <el-date-picker size="small" v-model="addAndEditData.paymentDate" type="date" value-format="yyyy-MM-dd"
            placeholder="请选择缴费时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="收费方式" prop="paymentMethod">
          <el-select v-model="addAndEditData.paymentMethod" placeholder="请选择收费方式" size="small" clearable>
            <el-option v-for="item in paymentMethodList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收费金额" prop="paymentMoney">
          <el-input-number v-model="addAndEditData.paymentMoney" placeholder="请输入收费金额" :min="0"
            :precision="2"></el-input-number>
        </el-form-item>
        <el-form-item label="收款账户" prop="makeCollectionsAccount">
          <el-input v-model="addAndEditData.makeCollectionsAccount" placeholder="请输入收款账户"></el-input>
        </el-form-item>
        <el-form-item label="管理成本" prop="costMoney">
          <el-input-number v-model="addAndEditData.costMoney" placeholder="请输入管理成本" :min="0"
            :precision="2"></el-input-number>
        </el-form-item>
        <el-form-item label="开票状态" prop="costMoney">
          <el-select v-model="addAndEditData.invoiceState" placeholder="请选择开票状态" size="small" clearable>
            <el-option v-for="item in invoiceStateList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="票据编号" prop="invoiceCode">
          <el-input v-model="addAndEditData.invoiceCode" placeholder="请输入票据编号"></el-input>
        </el-form-item>
        <el-form-item label="付款凭证编号" prop="paymentCode">
          <el-input v-model="addAndEditData.paymentCode" placeholder="请输入付款凭证编号"></el-input>
        </el-form-item>
        <el-form-item label="缴费凭证">
          <el-upload :on-change="handlebusinessLicense" :before-upload="$beforeAvatarUpload" :http-request="$requestMine"
            :show-file-list="false" class="img-el-upload" action accept="image/png, image/gif, image/jpg, image/jpeg">
            <el-image :src="addAndEditData.paymentPhotoUrl ||
              require('@/assets/develop.png')
              " fit="contain" class="imgCenter"></el-image>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" class="bgc-bv" size="small" @click="cancelData">取消</el-button>
        <el-button type="primary" class="bgc-bv" size="small" @click="saveData">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "compulsoryCourses",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      retrievalData: {
        userName: "", // 学员姓名
        mobile: "", // 手机号
        idcard: "", // 身份证号
        auditState: "", // 审核结果
        paymentState: "", // 缴费状态
        paymentMethod: "", // 缴费方式
        occupationId: "", // 培训工种
        teacherId: "", // 招生老师
        registrationTime: [], // 报名时间
        workUnit: "",
      },
      // 审核结果 - 下拉数据
      auditStateList: [],
      // 缴费方式 - 下拉数据
      paymentMethodList: [],
      // 培训工种 - 下拉数据
      occupationList: [],
      // 招生老师 - 下拉数据
      teacherIdList: [],
      // 缴费状态 - 下拉数据
      paymentStateList: [],
      // 开票状态 - 下拉数据
      invoiceStateList: [],
      // 列表勾选数据
      multipleSelection: [],
      // 新增&编辑课程 - 弹框
      addAndEditData: {
        dialogVisible: false, // 弹框显示
        projectApplyIdList: [], // 学员申请id集合
        paymentDate: "", // 缴费日期
        paymentMethod: "", // 收费方式
        paymentMoney: "", // 收款金额
        makeCollectionsAccount: "", // 收款账户
        costMoney: "", // 管理成本
        invoiceState: "", // 开票状态
        invoiceCode: "", // 票据编号
        paymentCode: "", // 付款凭证编号
        paymentPhoto: "", // 票据凭证key
        paymentPhotoUrl: "", // 票据凭证url
      },
      // 新增&编辑课程 - 数据校验
      addAndEditDataRules: {
        paymentDate: [
          { required: true, message: "请选择缴费日期", trigger: "change" },
        ],
        paymentMethod: [
          { required: true, message: "请选择收费方式", trigger: "change" },
        ],
        paymentMoney: [
          { required: true, message: "请输入收款金额", trigger: "blur" },
        ],
      },
    };
  },
  watch: {},
  created() {
    this.getInvoice_type();
    this.getCtChannelSelectList();
    this.searchCompOccupation();
  },
  computed: {},
  mounted() { },
  methods: {
    // 获取 - 学员信息 - 下拉数据
    superUserSelect(e) {
      this.$post(
        "/biz/user/superUserSelect",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          userName: this.userDatas.seaUserName,
          idcard: this.userDatas.seaUserIdcard,
          mobile: this.userDatas.seaUserMobile,
          projectId: this.userDatas.projectId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.userDatas.userList = [{}];
        } else {
          this.userDatas.userList = res.data.list;
        }
      });
    },
    // 获取 - 学员信息 - 下拉框出现/隐藏时触发
    clearUserSearchModel(e) {
      if (e) {
        this.userDatas.seaUserName = "";
        this.userDatas.seaUserIdcard = "";
        this.userDatas.seaUserMobile = "";
        this.superUserSelect();
      }
    },
    // 获取 - 获取字典
    getInvoice_type() {
      // 审核结果
      let arr1 = this.$setDictionary("CT_USER_AUDIT_STATE", "list");
      for (const key in arr1) {
        this.auditStateList.push({
          label: arr1[key],
          value: key
        });
      }
      // 缴费方式 
      let arr2 = this.$setDictionary("CT_USER_APPLY_PAYMENT_METHOD_STATE", "list");
      for (const key in arr2) {
        if(key != '40'){
          this.paymentMethodList.push({
            label: arr2[key],
            value: key
          });
        }
      }
      this.paymentMethodList.push({
        label: "其他",
        value: '40'
      })
      // // 培训等级
      // let arr3 = this.$setDictionary("ET_TRAIN_LEVEL", "list");
      // for (const key in arr3) {
      //   this.trainLevelList.push({
      //     label: arr3[key],
      //     value: key
      //   });
      // }
      // 缴费状态
      let arr4 = this.$setDictionary("CT_USER_PAYMENT_STATE", "list");
      for (const key in arr4) {
        this.paymentStateList.push({
          label: arr4[key],
          value: key
        });
      }
      // 开票状态
      let arr5 = this.$setDictionary("CT_USER_APPLY_PAYMENT_INVOICE_STATE", "list");
      for (const key in arr5) {
        this.invoiceStateList.push({
          label: arr5[key],
          value: key
        });
      }
    },
    // 获取 - 培训工种
    searchCompOccupation() {
      this.$post('/biz/ct/occupation/select/pageList', {
        pageNum: 1,
        pageSize: 10,
        occupationName: ""
      }).then(ret => {
        this.occupationList = ret.data || []
      }).catch(err => {
        return;
      })
    },
    // 获取 - 培训老师
    getCtChannelSelectList() {
      this.$post('/biz/ct/channel/ctChannelSelectList', {
        contactUser: ''
      }).then(ret => {
        this.teacherIdList = ret.data || [];
        this.teacherIdList.unshift({
          contactUser: "自招",
          channelId: -1000,
        });
      }).catch(err => {
        return;
      })
    },
    // 获取 - 列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.retrievalData.userName) {
        params.userName = this.retrievalData.userName;
      }
      if (this.retrievalData.mobile) {
        params.mobile = this.retrievalData.mobile;
      }
      if (this.retrievalData.idcard) {
        params.idcard = this.retrievalData.idcard;
      }
      if (this.retrievalData.workUnit) {
        params.workUnit = this.retrievalData.workUnit;
      }
      if (this.retrievalData.auditState) {
        params.auditState = this.retrievalData.auditState;
      }
      if (this.retrievalData.paymentMethod) {
        params.paymentMethod = this.retrievalData.paymentMethod;
      }
      if (this.retrievalData.occupationId) {
        params.occupationId = this.retrievalData.occupationId;
      }
      if (this.retrievalData.teacherId) {
        params.teacherId = this.retrievalData.teacherId;
      }
      if (this.retrievalData.paymentState) {
        params.paymentState = this.retrievalData.paymentState;
      }
      if (this.retrievalData.registrationTime !== null && this.retrievalData?.registrationTime.length) {
        params.applyStartDate = this.retrievalData.registrationTime[0];
        params.applyEndDate = this.retrievalData.registrationTime[1];
      }
      this.doFetch({
        url: "/biz/ct/user/apply/payment/pageList",
        params,
        pageNum,
      });
    },
    // 列表 - 勾选 
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 新增&编辑(缴费) - 弹框-打开
    setAddAndEdit(row) {
      this.cancelData();
      if (row?.projectApplyId) {// 编辑
        this.addAndEditData = { ... this.addAndEditData, ...row };
        this.addAndEditData.projectApplyIdList.push(row.projectApplyId);
        this.addAndEditData.paymentDate = row.paymentDate ? this.$moment(row.paymentDate).format("YYYY-MM-DD") : this.$moment(new Date()).format("YYYY-MM-DD");
        this.addAndEditData.paymentMethod = row.paymentMethod ? row.paymentMethod : '10';
      } else {
        if (this.multipleSelection.length) {
          for (let i = 0; i < this.multipleSelection.length; i++) {
            this.addAndEditData.projectApplyIdList.push(this.multipleSelection[i].projectApplyId);
          }
          this.addAndEditData.paymentDate = this.$moment(new Date()).format("YYYY-MM-DD");
          this.addAndEditData.paymentMethod = '10';
        } else {
          this.$message.warning("请至少勾选一项");
          return
        }
      }
      this.addAndEditData.dialogVisible = true;
    },
    // 上传缴费凭证
    handlebusinessLicense(res) {
      let file = res.raw;
      const fileType = file.name.slice(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      const that = this;
      this.$Postformat("/sys/upload", formData).then((ret) => {
        that.addAndEditData.paymentPhotoUrl = ret.data.fileURL || "";
        that.addAndEditData.paymentPhoto = ret.data.fileKey || "";
      });
    },
    // 新增&编辑(缴费) - 弹框-取消
    cancelData() {
      this.addAndEditData = this.$options.data().addAndEditData;
    },
    // 新增&编辑(缴费) - 弹框-取消(清空所有数据)用
    cancelDataAll() {
      this.addAndEditData = this.$options.data().addAndEditData;
      this.$refs.multipleTable.clearSelection();
    },
    // 新增&编辑(缴费) - 弹框-确认
    saveData() {
      this.$refs['addAndEditData'].validate((valid) => {
        if (valid) {
          this.addAndEditData.paymentState = "20";
          this.$post("/biz/ct/user/apply/payment/update", this.addAndEditData).then((res) => {
            this.cancelDataAll();
            this.getData(this.pageNum);
            this.$message.success("操作成功");
          }).catch(() => {
            return;
          });
        }
      });
    },
    // 未缴费
    setUnpaidFees(row) {
      if (row?.projectApplyId) {// 单个操作
        this.addAndEditData.projectApplyIdList.push(row.projectApplyId);
      } else {
        if (this.multipleSelection.length) {
          for (let i = 0; i < this.multipleSelection.length; i++) {
            this.addAndEditData.projectApplyIdList.push(this.multipleSelection[i].projectApplyId);
          }
        } else {
          this.$message.warning("请在至少勾选一项");
          return
        }
      }
      this.addAndEditData.paymentState = "10";
      this.$post("/biz/ct/user/apply/payment/update", this.addAndEditData).then((res) => {
        this.cancelDataAll();
        this.getData(this.pageNum);
        this.$message.success("操作成功");
      }).catch(() => {
        return;
      });
    },
  },
};
</script>
<style lang="less" scope>
.addAndEditData {
  .el-form-item {
    .el-form-item__content {
      .el-input-number {
        width: 100%;
      }
    }
  }
}
</style>